import { useMemo } from 'react';
import { GlobalSearchStateInput } from '@hotelplan/graphql.types';
import { trackRecommendationsLoaded } from '@hotelplan/libs.tracking';
import { mapRecommendationsToTrackableData } from 'components/domain/recommendations/mappers';
import { Bd4recommendationFragment } from 'graphql/components/BD4RecommendationGroup.generated';
import { useGetSrlBd4AccordionItemsLazyQuery } from 'graphql/srl/bd4/GetSrlBd4AccordionItems.generated';

interface IUseBd4AccordionItem {
  name?: string;
  bdAccordionItems: Array<Bd4recommendationFragment>;
  bd4AccordionItemsLoading: boolean;
  requestAccordionItems: (options: {
    variables: {
      currentUri: string;
      encodedCriteria: string;
      gss?: GlobalSearchStateInput;
    };
  }) => void;
}

export default function useBd4AccordionItems(): IUseBd4AccordionItem {
  const [
    requestAccordionItems,
    { data: bdAccordionItemsData, loading: bd4AccordionItemsLoading },
  ] = useGetSrlBd4AccordionItemsLazyQuery({
    ssr: false,
    onCompleted: function trackBd4(completedData) {
      const { name, items, tracking } =
        completedData.srl.bD4TravelRecommendationsComponent
          .BD4TravelRecommendationAccordion;

      if (items?.length) {
        trackRecommendationsLoaded(
          mapRecommendationsToTrackableData(name, items, tracking)
        );
      }
    },
  });

  return useMemo(
    () => ({
      bdAccordionItems:
        bdAccordionItemsData?.srl.bD4TravelRecommendationsComponent
          .BD4TravelRecommendationAccordion.items,
      bd4AccordionItemsLoading,
      requestAccordionItems,
    }),
    [bd4AccordionItemsLoading, bdAccordionItemsData, requestAccordionItems]
  );
}

import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import {
  RecommendationList,
  RecommendationSectionTitle,
} from 'components/domain/recommendation-list';

export const ListWrapper = styled(RecommendationList)(
  sx2CssThemeFn({
    gridTemplateColumns: ['1fr 1fr', `repeat(3, 315px)`],
    '.product-image': {
      height: ['100px', '170px'],
    },
  })
);

export const SectionWrapper = styled.div(
  sx2CssThemeFn({
    '.recommendation-skeleton': {
      gridTemplateColumns: ['1fr 1fr', `repeat(3, 315px)`],
    },
  })
);

export const ListTitleWrapper = styled(RecommendationSectionTitle)(
  sx2CssThemeFn({
    fontSize: ['18px', '26px'],
  })
);

import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import {
  RecommendationList,
  RecommendationSectionTitle,
} from 'components/domain/recommendation-list';

export const ListTitleWrapper = styled(RecommendationSectionTitle)(
  sx2CssThemeFn({
    mb: 1,
    fontSize: [1, '18px'],
    fontWeight: 700,
    fontFamily: '"Arial", sans-serif',
    alignSelf: 'flex-start',
    ml: '5px',
  })
);

export const ListWrapper = styled(RecommendationList)(
  sx2CssThemeFn({
    gridTemplateColumns: ['1fr 1fr', `repeat(1, 240px)`],
    gap: 3,
    '.product-image': {
      height: ['100px', '170px'],
    },
    '.card-title, .product-image': {
      maxWidth: ['165px', '250px'],
    },
    '.rating .icon, .remainRating .icon, .halfWayRating .icon': {
      width: '17px',
      height: '17px',
      mr: '2px',
    },
    '.tripadvisorLogo': {
      width: '30px',
      height: '20px',
      mr: 2,
    },
  })
);

export const SectionWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '.recommendation-skeleton': {
      gridTemplateColumns: '100%',
      width: '100%',
    },
  })
);

import React, { PropsWithChildren } from 'react';
import RecommendationListSkeleton from './RecommendationList.skeleton';
import { RecommendationWrap } from './RecommendationList.styled';

interface IRecommendationListProps extends PropsWithChildren {
  loading?: boolean;
  className?: string;
}

function RecommendationList({
  children,
  className,
  loading,
}: IRecommendationListProps): React.ReactElement {
  if (loading) return <RecommendationListSkeleton />;
  return (
    <RecommendationWrap className={className}>{children}</RecommendationWrap>
  );
}

export default RecommendationList;

import styled from 'styled-components';
import { AccordionItem } from '@hotelplan/components.common.accordion';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const Bd4AccordionItemsWrapper = styled.div(
  sx2CssThemeFn({
    py: 3,
  })
);

export const Bd4AccordionItem = styled(AccordionItem)(
  ({ theme }) => theme.srl.accordionItem,
  sx2CssThemeFn({
    '.accordionButton': {
      py: '10px',
    },
  })
);

export const TitleWrapper = styled.div(
  sx2CssThemeFn({
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '.name': {
      flexGrow: '1',
      color: 'primary',
      textAlign: 'left',
      fontSize: [1, '18px'],
    },
  })
);

export const Bd4AccordionSkeletonWrapper = styled.div(
  sx2CssThemeFn({
    mb: 3,
  })
);

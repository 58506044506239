import React from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { CardSkeleton } from 'components/domain/card/Card.skeleton';

const ListWrapper = styled.div(
  sx2CssThemeFn({
    display: 'grid',
    gridTemplateColumns: [null, 'repeat(3, 1fr)'],
    gap: [3, '20px 35px'],
    mb: [4, 5],
  })
);

export default function RecommendationListSkeleton() {
  return (
    <ListWrapper className="recommendation-skeleton">
      {Array.from({ length: 6 }).map((_, index) => {
        return <CardSkeleton key={index} uid={`card-skeleton-${index}`} />;
      })}
    </ListWrapper>
  );
}

import React from 'react';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';

const CARD_WIDTH = 376;
const CARD_HEIGHT = 312;

interface ICardSkeletonProps {
  uid: string;
}

export function CardSkeleton({ uid }: ICardSkeletonProps): React.ReactElement {
  return (
    <PlaceholderLoader
      uid={uid}
      width={CARD_WIDTH}
      height={CARD_HEIGHT}
      bgColor={'white'}
      fgColor={'lightGray'}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M376 0H0V312H376V0ZM21 281C21 275.477 25.4772 271 31 271H102C107.523 271 112 275.477 112 281V287C112 292.523 107.523 297 102 297H31C25.4772 297 21 292.523 21 287V281ZM124 280C124 275.029 128.029 271 133 271C137.971 271 142 275.029 142 280V288C142 292.971 137.971 297 133 297C128.029 297 124 292.971 124 288V280Z"
      />
    </PlaceholderLoader>
  );
}

import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import { GeoType, ProductType } from '@hotelplan/graphql.types';
import { formatPrice } from '@hotelplan/libs.utils';
import { Bd4recommendationFragment } from 'graphql/components/BD4RecommendationGroup.generated';
import { mapDurationToWeeks } from 'libs';
import { Bd4ItemCardWrapper } from './Bd4Item.styled';

export default function Bd4ItemCard({
  name,
  price,
  image,
  link,
  duration,
  mealType,
  geoBreadcrumbs,
  type,
}: Bd4recommendationFragment): React.ReactElement {
  const [t] = useTranslation('common');

  const destination = useMemo(
    () =>
      geoBreadcrumbs
        // Find the destination breadcrumb
        ?.find(geo => geo.type === GeoType.Destination)?.name ||
      // If no destination breadcrumb is found, use the first breadcrumb which is always the country
      geoBreadcrumbs?.[0]?.name ||
      '',
    [geoBreadcrumbs]
  );

  const durationText = mapDurationToWeeks(duration, t);
  const prefix = t('common:priceFrom');
  const offerTravelType = type
    ? type === ProductType.Package
      ? `${t('common:offer.travelType.package')}`
      : `${t('common:offer.travelType.hotel')}`
    : '';
  const cardText = `<p>${destination}, ${durationText}</p><p>${offerTravelType}</p><p>${mealType}</p>`;

  return (
    <Bd4ItemCardWrapper
      cardTitle={<span className="card-title">{name}</span>}
      image={image}
      btnText={`${prefix} ${formatPrice(price)}`}
      btnIcon={{
        name: 'chevron-long-right',
        size: 'sm',
      }}
      link={link}
      cardText={cardText}
    />
  );
}

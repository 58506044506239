import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const RecommendationWrap = styled.div(
  sx2CssThemeFn({
    display: `grid`,
    gridTemplateColumns: ['1fr', `repeat(3, 1fr)`],
    gap: ['25px 15px', '35px 18px'],
    gridAutoFlow: [null, 'row'],
    mb: [4, 5],
  })
);

export const RecommendationSectionTitle = styled.h2(
  sx2CssThemeFn({
    fontSize: [2, '26px'],
    my: 3,
    ml: [3, 0],
  })
);

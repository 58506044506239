import React from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { IBd4Props } from 'components/domain/bd4/bd4.types';
import { Bd4ItemCard } from 'components/domain/bd4/components';
import {
  ListTitleWrapper,
  ListWrapper,
  SectionWrapper,
} from './HorizontalBd4.styled';

const MAX_DESKTOP_ITEMS = 3;
const MAX_MOBILE_ITEMS = 2;

export default function HorizontalBd4({
  name,
  items,
  loading,
}: IBd4Props): React.ReactElement {
  const { mobile } = useDeviceType();
  const MAX_ITEMS = mobile ? MAX_MOBILE_ITEMS : MAX_DESKTOP_ITEMS;

  return (
    <SectionWrapper>
      {name ? <ListTitleWrapper>{name}</ListTitleWrapper> : null}
      <ListWrapper loading={loading}>
        {items?.slice(0, MAX_ITEMS).map(item => {
          return <Bd4ItemCard key={item.offerId} {...item} />;
        })}
      </ListWrapper>
    </SectionWrapper>
  );
}

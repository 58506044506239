import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import {
  Bd4recommendationFragmentDoc,
  Bd4TrackingFragmentDoc,
} from 'graphql/components/BD4RecommendationGroup.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetSrlBd4AccordionItemsQueryVariables = Types.Exact<{
  context?: Types.InputMaybe<Types.RequestContextInput>;
  currentUri: Types.Scalars['String'];
  encodedCriteria: Types.Scalars['String'];
  gss?: Types.InputMaybe<Types.GlobalSearchStateInput>;
}>;

export type GetSrlBd4AccordionItemsQuery = {
  __typename?: 'Query';
  srl: {
    __typename?: 'SrlContext';
    bD4TravelRecommendationsComponent: {
      __typename?: 'SrlBD4TravelRecommendationsComponent';
      BD4TravelRecommendationAccordion?: {
        __typename?: 'BD4TravelRecommendationGroup';
        name?: string | null;
        items: Array<{
          __typename?: 'BD4TravelRecommendation';
          offerId: string;
          name: string;
          travelType?: string | null;
          duration: number;
          mealType: string;
          hpRating?: number | null;
          taRating?: number | null;
          taReviews?: number | null;
          productClass: Types.ProductClass;
          type: Types.ProductType;
          giata: string;
          transferIncluded?: boolean | null;
          featureSummary: Array<{
            __typename?: 'ProductFeatureGroup';
            id: string;
            name: string;
            features: Array<{
              __typename?: 'ProductFeature';
              id: string;
              name: string;
              enabled?: boolean | null;
            }>;
          }>;
          geoBreadcrumbs: Array<{
            __typename?: 'GeoObject';
            id: string;
            name: string;
            type: Types.GeoType;
          }>;
          link: {
            __typename?: 'InternalLink';
            caption?: string | null;
            uri: string;
            targetPageType: Types.PageType;
          };
          price: { __typename?: 'Price'; amount: string; currency: string };
          totalPrice: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          };
          transferPrice?: {
            __typename?: 'Price';
            amount: string;
            currency: string;
          } | null;
          image: {
            __typename?: 'Image';
            alt: string;
            resized: Array<{
              __typename?: 'ResizedImage';
              url: string;
              id: string;
            }>;
          };
        }>;
        tracking?: {
          __typename?: 'BD4TravelRecommendationTracking';
          status: Types.Bd4TravelRecommendationStatus;
          productCount?: number | null;
          productIds?: Array<string> | null;
          ptoken?: string | null;
          info?: {
            __typename?: 'BD4TravelRecommendationInfo';
            modelId: string;
            placementId: string;
            strategy: string;
          } | null;
        } | null;
      } | null;
    };
  };
};

export const GetSrlBd4AccordionItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSrlBd4AccordionItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'currentUri' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'encodedCriteria' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'gss' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GlobalSearchStateInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'bD4TravelRecommendationsComponent',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'gss' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'gss' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'currentUri' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'currentUri' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'encodedCriteria' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'encodedCriteria' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'BD4TravelRecommendationAccordion',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'items' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'bd4recommendation',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tracking' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'bd4Tracking',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...Bd4recommendationFragmentDoc.definitions,
    ...Bd4TrackingFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetSrlBd4AccordionItemsQuery__
 *
 * To run a query within a React component, call `useGetSrlBd4AccordionItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSrlBd4AccordionItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSrlBd4AccordionItemsQuery({
 *   variables: {
 *      context: // value for 'context'
 *      currentUri: // value for 'currentUri'
 *      encodedCriteria: // value for 'encodedCriteria'
 *      gss: // value for 'gss'
 *   },
 * });
 */
export function useGetSrlBd4AccordionItemsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetSrlBd4AccordionItemsQuery,
    GetSrlBd4AccordionItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetSrlBd4AccordionItemsQuery,
    GetSrlBd4AccordionItemsQueryVariables
  >(GetSrlBd4AccordionItemsDocument, options);
}
export function useGetSrlBd4AccordionItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSrlBd4AccordionItemsQuery,
    GetSrlBd4AccordionItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetSrlBd4AccordionItemsQuery,
    GetSrlBd4AccordionItemsQueryVariables
  >(GetSrlBd4AccordionItemsDocument, options);
}
export type GetSrlBd4AccordionItemsQueryHookResult = ReturnType<
  typeof useGetSrlBd4AccordionItemsQuery
>;
export type GetSrlBd4AccordionItemsLazyQueryHookResult = ReturnType<
  typeof useGetSrlBd4AccordionItemsLazyQuery
>;
export type GetSrlBd4AccordionItemsQueryResult = Apollo.QueryResult<
  GetSrlBd4AccordionItemsQuery,
  GetSrlBd4AccordionItemsQueryVariables
>;

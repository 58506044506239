import camelCase from 'lodash/camelCase';
import { Bd4TravelRecommendationStatus } from '@hotelplan/graphql.types';
import { RecommendationsLoadedPayload } from '@hotelplan/libs.tracking';
import { RecommendationItemT } from 'components/domain/recommendations/types';
import { Bd4TrackingFragment } from 'graphql/components/BD4RecommendationGroup.generated';

const isBD4TravelSuccessStatus = (
  status?: Bd4TravelRecommendationStatus
): boolean => {
  return status === Bd4TravelRecommendationStatus.Success;
};

export const mapRecommendationsToTrackableData = (
  name: string,
  items: RecommendationItemT[],
  tracking?: Bd4TrackingFragment | null
): RecommendationsLoadedPayload => {
  const payload: RecommendationsLoadedPayload = {
    type: '',
    title: name,
  };
  items.forEach(item => {
    switch (item.__typename) {
      case 'ThemeRecommendationItem': {
        const newItem = {
          count: 0,
          id: item.id,
          price: item.price.amount,
        };
        payload.type = 'themes';
        payload.themes = payload.themes
          ? [...payload.themes, newItem]
          : [newItem];
        break;
      }
      case 'ProductRecommendationItem': {
        const newItem = {
          count: 0,
          id: item.offerId,
          price: item.price.amount,
        };
        payload.type = 'products';
        payload.themes = payload.themes
          ? [...payload.themes, newItem]
          : [newItem];
        break;
      }
      case 'MarketingRecommendationItem':
        payload.type = 'static';
        break;
      case 'BD4TravelRecommendation': {
        payload.type = 'bd4t';
        if (isBD4TravelSuccessStatus(tracking?.status)) {
          const newItem = {
            id: item.giata,
            price: item.price.amount,
          };
          payload.products = payload.products
            ? [...payload.products, newItem]
            : [newItem];
          payload.bd4t = {
            placementID: tracking?.info?.placementId || '',
            ptoken: tracking?.ptoken || '',
            info: {
              placementId: tracking?.info?.placementId || '',
              modelId: tracking?.info?.modelId || '',
              strategy: tracking?.info?.strategy || '',
            },
            count: tracking?.productCount || 0,
          };
        } else {
          payload.bd4t = {
            placementID: tracking?.info?.placementId || '',
            error: camelCase(tracking?.status || ''),
          };
        }
        break;
      }
      case 'GeoRecommendationItem': {
        const newItem = {
          id: item.geoObject.id,
          price: item.perPersonPrice.amount,
          count: item.count,
        };
        payload.type = 'destinations';
        payload.groups = payload.groups
          ? [...payload.groups, newItem]
          : [newItem];
        break;
      }
      default:
        break;
    }
  });

  return payload;
};

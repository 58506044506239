import { useTranslation } from 'next-i18next';
import React from 'react';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { TripAdvisorInfo } from '@hotelplan/components.common.tripadvisor-rating';
import { getRouterLink } from '@hotelplan/libs.router-link-utils';
import ProductRating from 'components/core/product/ProductRating';
import { Bd4recommendationFragment } from 'graphql/components/BD4RecommendationGroup.generated';
import {
  Bd4SrlItemImage,
  Bd4SrlItemWrapper,
  DetailsButton,
  PerPersonPrice,
  ProductTripInfoAndTransferGroupWrapper,
} from './Bd4Item.styled';

export default function Bd4ItemSrlCard({
  name,
  image,
  link,
  hpRating,
  taRating,
  taReviews,
  geoBreadcrumbs,
  mealType,
  duration,
  type,
  transferIncluded,
  transferPrice,
  price,
  productClass,
}: Bd4recommendationFragment): React.ReactElement {
  const [t] = useTranslation('common');
  const routerProps = link ? getRouterLink(link) : null;

  return (
    <RouterLink {...routerProps} passHref>
      <Bd4SrlItemWrapper>
        {image ? <Bd4SrlItemImage {...image} /> : null}
        <div className="item-content">
          <ProductRating rating={hpRating} />
          <h2 className="product-title">{name}</h2>
          <div className="breadcrumbs">
            {geoBreadcrumbs.map(crumb => (
              <span key={crumb.id} className="breadcrumb">
                {crumb.name}
              </span>
            ))}
          </div>
          <TripAdvisorInfo
            rating={taRating || 0}
            reviewCount={taReviews || 0}
            label={t('common:tripAdvisor.reviewsCount')}
            className="tripadvisor-info"
          />
          <ProductTripInfoAndTransferGroupWrapper
            type={type}
            mealType={mealType}
            duration={duration}
            transferIncluded={transferIncluded}
            transferPrice={transferPrice}
            productClass={productClass}
          />
          <PerPersonPrice title={t('common:price.perPerson')} price={price} />
          <DetailsButton>{t('common:btn.details')}</DetailsButton>
        </div>
      </Bd4SrlItemWrapper>
    </RouterLink>
  );
}

import React, { useEffect } from 'react';
import { trackRecommendationsLoaded } from '@hotelplan/libs.tracking';
import { IBd4Props } from 'components/domain/bd4/bd4.types';
import { Bd4ItemSrlCard } from 'components/domain/bd4/components';
import { mapRecommendationsToTrackableData } from 'components/domain/recommendations/mappers';
import {
  ListTitleWrapper,
  ListWrapper,
  SectionWrapper,
} from './VerticalBd4.styled';

export default function VerticalBd4({
  name,
  items,
  loading,
  tracking,
}: IBd4Props): React.ReactElement {
  useEffect(
    function trackRecommendations() {
      if (!loading && items?.length) {
        trackRecommendationsLoaded(
          mapRecommendationsToTrackableData(name, items, tracking)
        );
      }
    },
    [loading, items]
  );

  return (
    <SectionWrapper>
      <ListTitleWrapper>{name}</ListTitleWrapper>
      <ListWrapper loading={loading}>
        {items?.map(item => {
          return <Bd4ItemSrlCard key={item.offerId} {...item} />;
        })}
      </ListWrapper>
    </SectionWrapper>
  );
}

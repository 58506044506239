import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { PageType } from '@hotelplan/graphql.types';
import { usePageType } from '@hotelplan/libs.context.page-type';
import {
  mapGlobalSearchStateToSearchControlCriteriaInput,
  useGSSContext,
} from '@hotelplan/libs.gss';
import useSearchParam from 'components/core/search/useSearchParam';
import { ISearchControlState } from 'components/domain/search-control/components/SearchControlForm/SearchControl.types';
import { useGetSrlBd4AccordionNameQuery } from 'graphql/srl/bd4/GetSrlBd4AccordionName.generated';

interface IUseBd4AccordionName {
  bd4AccordionName?: string;
  bd4AccordionLoading: boolean;
}

export default function useBd4Accordion(): IUseBd4AccordionName {
  const searchParam = useSearchParam();
  const pageType = usePageType<PageType>();
  const { asPath } = useRouter();
  const { gss } = useGSSContext<ISearchControlState>();

  const { data, loading } = useGetSrlBd4AccordionNameQuery({
    ssr: false,
    skip: pageType !== PageType.Srl,
    variables: {
      gss:
        !searchParam && gss
          ? mapGlobalSearchStateToSearchControlCriteriaInput(gss)
          : undefined,
      currentUri: asPath,
      encodedCriteria: searchParam || '',
    },
  });

  return useMemo(
    () => ({
      bd4AccordionName:
        data?.srl.bD4TravelRecommendationsComponent
          .BD4TravelRecommendationAccordion.name,
      bd4AccordionLoading: loading,
    }),
    [data, loading]
  );
}

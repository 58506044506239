import styled from 'styled-components';
import { Image } from '@hotelplan/components.common.image';
import { Link } from '@hotelplan/components.common.link';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import PrimaryButton from 'components/core/buttons/PrimaryButton';
import ProductPrice from 'components/core/product/ProductPrice';
import ProductTripInfoAndTransferGroup from 'components/core/product/ProductTripInfoAndTransferGroup';
import { Card } from 'components/domain/card';

export const Bd4ItemCardWrapper = styled(Card)(
  sx2CssThemeFn({
    borderLeft: '1px solid',
    borderRight: '1px solid',
    borderColor: 'grayMiddle',

    '.content': {
      pb: [5, 0],
    },
    '.card-title': {
      fontSize: '22px',
      maxWidth: ['150px', '315px'],
    },
    '.prefix': {
      fontSize: ['14px', 1],
    },
    '.price-count': {
      fontSize: '22px',
    },
    '.content-text': {
      'p:last-of-type': {
        maxWidth: '49%',
      },
    },
  })
);

export const Bd4SrlItemWrapper = styled(Link)(
  sx2CssThemeFn({
    position: 'relative',
    height: '100%',
    border: '1px solid',
    borderColor: 'borderGray',
    bg: 'white',
    '.item-content': {
      p: 2,
    },
    '.product-title': {
      color: 'secondary',
      fontSize: [1, '18px'],
      fontFamily: 'Helvetica Neue',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '.breadcrumbs': {
      fontSize: ['12px', '14px'],
      fontWeight: 'bold',
      mb: [1, 2],
      mt: 1,
      '.breadcrumb': {
        '&:not(:last-child):after': {
          content: '" | "',
        },
      },
    },
    '.tripadvisor-info': {
      fontSize: ['12px', 0],
      mb: [1, 3],
    },
  })
);

export const Bd4SrlItemImage = styled(Image)({
  width: '100%',
  height: '169px',
  objectFit: 'cover',
  display: 'block',
});

export const ProductTripInfoAndTransferGroupWrapper = styled(
  ProductTripInfoAndTransferGroup
)(
  sx2CssThemeFn({
    maxWidth: ['initial', '240px'],
    '.trip-info': {
      color: 'textGray',
      fontSize: ['12px', 0],
    },
  })
);

export const PerPersonPrice = styled(ProductPrice)(
  sx2CssThemeFn({
    mt: 3,
    '.title': {
      color: 'textGray',
      fontWeight: 400,
    },
    '.value': {
      color: 'secondary',
    },
  })
);

export const DetailsButton = styled(PrimaryButton)(
  sx2CssThemeFn({
    position: 'absolute',
    bottom: 0,
    right: 0,
    textTransform: 'uppercase',
  })
);

import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetSrlBd4AccordionNameQueryVariables = Types.Exact<{
  context?: Types.InputMaybe<Types.RequestContextInput>;
  currentUri: Types.Scalars['String'];
  encodedCriteria: Types.Scalars['String'];
  gss?: Types.InputMaybe<Types.GlobalSearchStateInput>;
}>;

export type GetSrlBd4AccordionNameQuery = {
  __typename?: 'Query';
  srl: {
    __typename?: 'SrlContext';
    bD4TravelRecommendationsComponent: {
      __typename?: 'SrlBD4TravelRecommendationsComponent';
      BD4TravelRecommendationAccordion?: {
        __typename?: 'BD4TravelRecommendationGroup';
        name?: string | null;
      } | null;
    };
  };
};

export const GetSrlBd4AccordionNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSrlBd4AccordionName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'currentUri' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'encodedCriteria' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'gss' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GlobalSearchStateInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'srl' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'bD4TravelRecommendationsComponent',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'gss' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'gss' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'currentUri' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'currentUri' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'encodedCriteria' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'encodedCriteria' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'BD4TravelRecommendationAccordion',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetSrlBd4AccordionNameQuery__
 *
 * To run a query within a React component, call `useGetSrlBd4AccordionNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSrlBd4AccordionNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSrlBd4AccordionNameQuery({
 *   variables: {
 *      context: // value for 'context'
 *      currentUri: // value for 'currentUri'
 *      encodedCriteria: // value for 'encodedCriteria'
 *      gss: // value for 'gss'
 *   },
 * });
 */
export function useGetSrlBd4AccordionNameQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetSrlBd4AccordionNameQuery,
    GetSrlBd4AccordionNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetSrlBd4AccordionNameQuery,
    GetSrlBd4AccordionNameQueryVariables
  >(GetSrlBd4AccordionNameDocument, options);
}
export function useGetSrlBd4AccordionNameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSrlBd4AccordionNameQuery,
    GetSrlBd4AccordionNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetSrlBd4AccordionNameQuery,
    GetSrlBd4AccordionNameQueryVariables
  >(GetSrlBd4AccordionNameDocument, options);
}
export type GetSrlBd4AccordionNameQueryHookResult = ReturnType<
  typeof useGetSrlBd4AccordionNameQuery
>;
export type GetSrlBd4AccordionNameLazyQueryHookResult = ReturnType<
  typeof useGetSrlBd4AccordionNameLazyQuery
>;
export type GetSrlBd4AccordionNameQueryResult = Apollo.QueryResult<
  GetSrlBd4AccordionNameQuery,
  GetSrlBd4AccordionNameQueryVariables
>;

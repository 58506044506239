import { useRouter } from 'next/router';
import React from 'react';
import { IAccordionItemProps } from '@hotelplan/components.common.accordion';
import {
  mapGlobalSearchStateToSearchControlCriteriaInput,
  useGSSContext,
} from '@hotelplan/libs.gss';
import useSearchParam from 'components/core/search/useSearchParam';
import useBd4AccordionItems from 'components/domain/bd4/modules/Accordion/hooks/useBd4AccordionItems';
import { ISearchControlState } from 'components/domain/search-control/components/SearchControlForm/SearchControl.types';
import SrlProductItem from 'components/domain/srl/SrlProductItem';
import SRLProductsSkeleton from 'components/domain/srl/SRLProducts.skeleton';
import {
  Bd4AccordionItem,
  Bd4AccordionItemsWrapper,
  TitleWrapper,
} from './AccordionBd4.styled';

interface IAccordionBd4Props extends Omit<IAccordionItemProps, 'title'> {
  title: string;
}

export default function AccordionBd4(
  props: IAccordionBd4Props
): React.ReactElement {
  const { onOpen, title, ...restProps } = props;

  const searchParam = useSearchParam();
  const { asPath } = useRouter();
  const { gss } = useGSSContext<ISearchControlState>();

  const { bdAccordionItems, requestAccordionItems, bd4AccordionItemsLoading } =
    useBd4AccordionItems();

  function handleRequestAccordionItems() {
    requestAccordionItems({
      variables: {
        currentUri: asPath,
        encodedCriteria: searchParam,
        gss:
          !searchParam && gss
            ? mapGlobalSearchStateToSearchControlCriteriaInput(gss)
            : undefined,
      },
    });
    onOpen?.();
  }

  if (!title) return null;

  return (
    <Bd4AccordionItem
      title={
        <TitleWrapper>
          <div className="name">{title}</div>
        </TitleWrapper>
      }
      customIcon={{ activeIcon: 'minus', inactiveIcon: 'plus' }}
      onOpen={handleRequestAccordionItems}
      {...restProps}
    >
      {active => {
        if (!active) return null;
        if (bd4AccordionItemsLoading) {
          return <SRLProductsSkeleton count={3} />;
        }

        return (
          <Bd4AccordionItemsWrapper>
            {bdAccordionItems?.map(item => (
              <SrlProductItem key={item.offerId} {...item} />
            ))}
          </Bd4AccordionItemsWrapper>
        );
      }}
    </Bd4AccordionItem>
  );
}

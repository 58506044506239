import React from 'react';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { Bd4AccordionSkeletonWrapper } from './AccordionBd4.styled';

const WIDTH = 364;
const HEIGHT = 20;

export default function Bd4AccordionSkeleton(): React.ReactElement {
  return (
    <Bd4AccordionSkeletonWrapper>
      <PlaceholderLoader
        width={WIDTH}
        height={HEIGHT}
        uid="bd4-accordion-skeleton"
        style={{ height: '100%', width: '100%', display: 'block' }}
      >
        <rect y="0" x="0" width="675" height="20" rx="5" />
      </PlaceholderLoader>
    </Bd4AccordionSkeletonWrapper>
  );
}

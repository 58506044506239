import styled from 'styled-components';
import { Image } from '@hotelplan/components.common.image';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const CardWrapper = styled.div(
  sx2CssThemeFn({
    position: 'relative',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderLeft: ['none', '1px solid'],
    borderRight: ['none', '1px solid'],
    borderColor: ['#999', '#999'],
    backgroundColor: '#fff',
    height: '100%',
    ':hover': {
      cursor: 'pointer',
    },
    '.card-top': {
      backgroundColor: 'secondary',
      color: 'white',
      py: 2,
      px: '12px',
    },
    '.card-title-block': {
      fontSize: '26px',
      textShadow: '0 1px 3px rgba(0,0,0,0.2)',
    },
    '.card-title': {
      maxWidth: '150px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '.card-subtitle': {
      fontSize: ['14px', 1],
    },
    '.content': {
      minHeight: '60px',
    },
    '.content-text': {
      fontSize: '14px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      py: 2,
      px: ['10px', '12px'],
      lineHeight: [null, '1.4'],
    },
    '.button': {
      position: 'absolute',
      bottom: 0,
      right: 0,
      variant: 'buttons.allOffers',
    },
    a: {
      display: 'block',
    },
  })
);

export const CardImageWrapper = styled.div(
  sx2CssThemeFn({
    position: 'relative',
    width: '100%',
    height: '169px',
  })
);

export const CardImage = styled(Image)({
  objectFit: 'cover',
  display: 'block',
  width: '100%',
  height: '100%',
});

export const CardTitle = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: ['center', 'end'],
    '.prefix, .currency': {
      fontSize: 1,
    },
    '.currency': {
      mx: 1,
    },
  })
);

import React from 'react';
import { Button } from '@hotelplan/components.common.buttons';
import { IconSize } from '@hotelplan/components.common.icon';
import { INormalizedImage } from '@hotelplan/components.common.image';
import { Link } from '@hotelplan/components.common.link';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { DisrupterObject, Link as LinkType } from '@hotelplan/graphql.types';
import {
  getOpenMethod,
  getRouterLink,
} from '@hotelplan/libs.router-link-utils';
import { Disrupter, EDisrupterPlacement } from 'components/domain/disrupter';
import {
  CardImage,
  CardImageWrapper,
  CardTitle,
  CardWrapper,
} from './Card.styled';

interface ICardProps {
  link?: LinkType;
  cardTitle: React.ReactNode;
  cardText?: string;
  customCardText?: React.ReactNode;
  btnText?: React.ReactNode;
  btnIcon?: {
    name: string;
    size: IconSize;
  };
  image: INormalizedImage;
  disrupterObject?: DisrupterObject;
  onCardClick?(e: React.MouseEvent): void;
  onButtonClick?(e: React.MouseEvent): void;
  className?: string;
}

export default function Card({
  link,
  cardTitle,
  cardText,
  customCardText,
  btnText,
  btnIcon,
  image,
  onCardClick,
  onButtonClick,
  disrupterObject,
  className,
}: ICardProps): React.ReactElement {
  const routerLink = link ? getRouterLink(link) : null;

  return (
    <RouterLink {...routerLink} passHref>
      <Link target={link ? getOpenMethod(link) : undefined}>
        <CardWrapper onClick={onCardClick} className={`${className} card`}>
          <div className="card-top">
            <h3 className="card-title-block">
              <CardTitle>{cardTitle}</CardTitle>
            </h3>
          </div>
          <CardImageWrapper className="product-image">
            {image ? <CardImage {...image} /> : null}
            {disrupterObject ? (
              <Disrupter
                {...disrupterObject}
                placement={EDisrupterPlacement.CARD}
              />
            ) : null}
          </CardImageWrapper>

          <div className="content">
            {cardText ? (
              <div
                className="content-text"
                dangerouslySetInnerHTML={{ __html: cardText }}
              />
            ) : null}
            {customCardText ? (
              <div className="content-text">{customCardText}</div>
            ) : null}
            <Button className="button" onClick={onButtonClick} icon={btnIcon}>
              {btnText}
            </Button>
          </div>
        </CardWrapper>
      </Link>
    </RouterLink>
  );
}
